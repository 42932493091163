
import { mapActions, mapGetters, mapState } from 'vuex'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDETag from '@/components/pde/PDETag.vue'
import LogRocket from 'logrocket'

const unitMap = {
  hourly: 'hr',
  weekly: 'wk',
  annual: 'yr',
}
export default {
  name: 'NewJobCard',
  components: {
    PDEIcon,
    PDETag,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('account', ['division', 'stateMatch']),
    ...mapState('account', ['isAlliedUser']),

    hasSubmittedLead() {
      return this.job.Interests.filter((interest) => interest.type === 'SUBMIT-LEAD').length >= 1
    },

    displayPayEligible() {
      const allowedDivisions = ['CHA', 'WMS', 'GRA']
      if (this.$featureFlags.chsDisplayPay === 'Variation') allowedDivisions.push('CHS')
      return this.salaryUnit && this.job.salaryHigh && this.job.salaryLow && this.$featureFlags.displayPay124 && allowedDivisions.includes(this.division)
    },

    quickFacts() {
      if (!this.$featureFlags.jobBoardDetailView123) return this.job.quickFacts
      if (this.job.quickFacts.length <= 3 && this.$featureFlags.jobBoardDetailView123) return this.job.quickFacts.slice(0, this.job.quickFacts.length - 1)
      const cloneQuickFacts = [...this.job.quickFacts]
      return cloneQuickFacts.slice(0, 2)
    },

    viewMoreFact() {
      return this.$featureFlags.jobBoardDetailView123 ? this.job.quickFacts[this.job.quickFacts.length > 2 ? 2 : this.job.quickFacts.length - 1] : null
    },

    salaryUnit() {
      return this.job.salaryUnit ? unitMap[this.job.salaryUnit] : null
    },

    salaryText() {
      return this.displayPayEligible ? `${this.job.salaryLow}-${this.job.salaryHigh}/${this.salaryUnit}` : null
    },

    sendInterestABTestFlagValue() {
      return this.$featureFlags.portalOptimization
    },

    isStateMatch() {
      return this.stateMatch(this.job.state)
    },

    isIMLC() {
      return this.job.isIMLC && !this.isAlliedUser
    },
  },

  methods: {
    ...mapActions('navigation', ['toggleSendToRepModal', 'toggleNewJobDetails']),
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('jobs', ['setSendJobToRepModalDetails', 'setNewJobDetailsData', 'setIsNewJob', 'setJobDetailsViewed']),

    handleSendInterestAnalytics() {
      this.sendClickEvent({
        name: 'Send Interest Click',
        text: 'Send Interest',
        area: 'action-pane/new-jobs',
      })
    },

    handleViewMoreAnalytics() {
      let detailText = `${this.displayPayEligible ? 'pay range, ' : ''}${this.isStateMatch ? 'State match, ' : ''}${this.isIMLC ? 'IMLC' : ''}`

      detailText = detailText.slice(-1) === ' ' ? detailText.slice(0, -2) : detailText
      this.sendClickEvent({
        name: 'New Jobs View More',
        text: 'view more',
        area: 'action-pane/new-jobs',
        detail: detailText,
      })
    },

    openSendToRepModalAndTrack(job) {
      // Temp LogRocket tracking for Send To Rep Click
      if (this.$featureFlags.logRocketSendInterest) {
        LogRocket.track('Send To Rep Click')
      }
      this.handleSendInterestAnalytics()
      this.setSendJobToRepModalDetails(job)
      this.toggleSendToRepModal()
    },

    dateString(date) {
      const now = this.$dayjs()
      const yesterday = now.subtract(1, 'day')
      const updateDate = this.$dayjs(date)

      if (now.isSame(updateDate, 'day')) {
        const minutes = now.diff(updateDate, 'minute')
        if (minutes <= 30) return 'Just posted'
        if (minutes <= 90) return 'Posted an hour ago'
        return 'Posted today'
      } else if (yesterday.isSame(updateDate, 'day')) {
        return 'Posted yesterday'
      } else {
        const days = now.diff(updateDate, 'day')
        return `Posted ${days > 10 ? '10+' : days} days ago`
      }
    },
    handleNewJobDetailsClick(job) {
      this.handleViewMoreAnalytics()
      this.setNewJobDetailsData({
        ...job,
        dateText: `${job.formattedDateRange}  ${!job.startDate && !job.endDate ? '' : '•'} ${this.dateString(job.updatedDate)}`,
        salaryText: this.salaryText,
        stateMatch: this.isStateMatch,
      })
      this.setSendJobToRepModalDetails(job) // this sets the current job as 'send to rep' in jobs store
      this.toggleNewJobDetails()
      this.setIsNewJob({ jobId: job.id, value: false })
      this.setJobDetailsViewed(job.id)
    },
  },
}
