import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "flex flex-col h-full",
  "data-test": "pde-tabs"
}
const _hoisted_2 = {
  "data-test": "pde-tab-headers",
  class: "flex w-full p-0 m-0 list-none border-0 border-b-1 border-pureblack-20 border-solid"
}
const _hoisted_3 = ["id", "data-test", "onClick"]
const _hoisted_4 = {
  class: "flex-1 overflow-hidden",
  "data-test": "pde-tab-content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("li", {
          id: `tab-${tab.key}`,
          key: tab.key,
          "data-test": `tab-${tab.key}`,
          class: _normalizeClass(["h-48 border-solid border-0 flex justify-center box-border px-16 items-center font-600 text-12 cursor-pointer font-default", [$options.getActiveClass(tab.key), $options.tabWidthClass]]),
          onClick: $event => ($options.selectTab(tab.key))
        }, _toDisplayString(tab.name), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: tab.key,
          class: "h-full"
        }, [
          ($options.isActive(tab.key))
            ? _renderSlot(_ctx.$slots, `tab-${tab.key}`, { key: 0 })
            : _createCommentVNode("", true)
        ])), [
          [_vShow, $options.isActive(tab.key)]
        ])
      }), 128))
    ])
  ]))
}