
import ScheduleCard from './ScheduleCard.vue'

export default {
  components: {
    ScheduleCard,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },

  methods: {
    formatMonth(month) {
      return this.$dayjs.utc(month).format('MMMM YYYY')
    },

    getDayOfTheWeek(day) {
      return this.$dayjs.utc(day).format('ddd')
    },

    getDayOfTheMonth(day) {
      return this.$dayjs.utc(day).format('D')
    },
  },
}
