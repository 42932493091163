/* eslint-disable complexity */

const pathMapObj = {
  'Medical Technologist': 'jobs/laboratory-professionals/medical-laboratory-technologist',
  'Pathology Assistant': 'jobs/laboratory-professionals/pathologists-assistant',
  Phlebotomist: 'jobs/laboratory-professionals',
  'Medical Assistant': 'jobs/laboratory-professionals/medical-assistant',
  Microbiologist: 'jobs/laboratory-professionals/microbiologist',
  'Medical Laboratory Technician': 'jobs/laboratory-professionals/medical-laboratory-technician',
  'Pain Management': 'jobs/advanced-practice/physician-assistant-pain-management',
  'Sleep Technician': 'jobs/therapy/polysomnographer',
  'Physical Therapy Assistant': 'jobs/therapy/physical-therapist-assistant',
  'Physical Therapy': 'jobs/therapy/physical-therapist',
  'Respiratory Therapy': 'jobs/therapy/respiratory-therapist',
  'Occupational Therapy': 'jobs/therapy/occupational-therapist',
  'Speech Language Pathology': 'jobs/therapy/speech-language-pathologist',
  Cytotech: 'jobs',
}

const specialtyMapObj = {
  'PA - Cardiovascular/Cardiothoracic Surgery': 'cardiac-surgery',
  'PA - Critical Care/Intensive Care': 'critical-care',
  'PA - Medical Oncology': 'oncology',
  'PA - Neurosurgery': 'neurological-surgery',
  'PA - Neurology': 'neurological-surgery',
  'NP - Critical Care/Intensive Care': 'critical-care',
  'NP - Cardiovascular/Cardiothoracic Surgery': 'cardiac-surgery',
  'NP - Neurosurgery': 'neurological-surgery',
  'NP - Medical Oncology': 'oncology',
  'NP - Hematology Oncology': 'oncology',
  'NP - Neurology': 'neurological-surgery',
  'Interventional Cardiology': 'cardiology',
  'Pulmonology/Critical Care': 'critical-care-medicine',
  Neurosurgery: 'neurological-surgery',
  'Hematology / Oncology': 'hematology-oncology',
  'Urgent Care': 'urgent-care-medicine',
  'Pediatric General Surgery': 'pediatric-surgery',
  'Maternal Fetal Medicine/Perinatology': 'maternal-fetal-medicine-perinatology',
  'Pediatric Critical Care': 'pediatric-critical-care-medicine',
  'Cardiac Anesthesia': 'anesthesiology',
  Electrophysiology: 'cardiology',
  'Pediatric Anesthesia': 'pediatric-anesthesiology',
  Gynecology: 'obstetrics-and-gynecology',
  'Invasive Cardiology': 'cardiology',
  'Med Ped': 'internal-medicine-pediatrics',
  'Pediatric Rheumatology': 'rheumatology',
  'Pulmonology Sleep Medicine': 'pulmonology',
  'General Trauma Surgery': 'trauma-surgery',
  'Reproductive Endocrinology': 'reproductive-endocrinology-and-infertility',
  'Orthopedic Trauma Surgery': 'orthopedic-surgery',
  'General Medicine': 'internal-medicine',
  'Pediatric Medical Genetics': 'pediatrics',
  'Psychiatry Inpatient': 'psychiatry',
  'Critical Care Anesthesia': 'anesthesiology',
  'Orthopedic Hand Surgery': 'orthopedic-surgery',
  'Pediatric Otolaryngology': 'otolaryngology',
  Hematology: 'hematology-oncology',
  Obstetrics: 'obstetrics-and-gynecology',
  'Pediatric Orthopedic Surgery': 'orthopedic-surgery',
}
export const jobsGenerateDynamicURL = (specialty, foxID, division) => {
  let path = ''
  let divisionBaseURL = ''

  // ---[ Divisions: Base-Url & Path ]------------------------------------------------------------
  if (['CHS', 'CHA', 'GRA'].includes(division)) {
    divisionBaseURL = 'https://comphealth.com/'

    if (specialty.startsWith('PA - ')) {
      path = 'jobs/physician-assistant/physician-assistant-replaceWithSpecialty'
    } else if (specialty.startsWith('NP - ')) {
      path = 'jobs/nurse-practitioner/nurse-practitioner-replaceWithSpecialty'
    } else if (specialty.startsWith('COTA - ')) {
      path = 'jobs/therapy/certified-occupational-therapy-assistant'
    } else {
      if (specialty in pathMapObj) {
        path = pathMapObj[specialty]
      } else {
        path = 'jobs/physician/replaceWithSpecialty'
      }
    }
  } else if (['WMS', 'WBY'].includes(division)) {
    divisionBaseURL = 'https://weatherbyhealthcare.com/'

    if (specialty.startsWith('PA - ')) {
      path = 'locum-tenens-physician-assistant-jobs/physician-assistant-replaceWithSpecialty'
    } else if (specialty.startsWith('NP - ')) {
      path = 'locum-tenens-nurse-practitioner-jobs/nurse-practitioner-replaceWithSpecialty'
    } else {
      path = 'locum-tenens-physician-jobs/replaceWithSpecialty'
    }
  }

  // ---[ ReplaceWithSpecialty ]-----------------------------------------------------------------
  if (path.includes('replaceWithSpecialty')) {
    if (specialty in specialtyMapObj) {
      path = path.replace('replaceWithSpecialty', specialtyMapObj[specialty])
    } else if (specialty.startsWith('PA - ')) {
      path = path.replaceAll('replaceWithSpecialty', specialty.replaceAll('PA - ', '').replaceAll(' ', '-').replaceAll('/', '-and-').replaceAll('&', 'and').replaceAll('--', '-').toLowerCase())
    } else if (specialty.startsWith('NP - ')) {
      path = path.replaceAll('replaceWithSpecialty', specialty.replaceAll('NP - ', '').replaceAll(' ', '-').replaceAll('/', '-and-').replaceAll('&', 'and').replaceAll('--', '-').toLowerCase())
    } else if (specialty.startsWith('Geriatric Medicine -')) {
      path = path.replaceAll('replaceWithSpecialty', 'geriatric-medicine')
    } else {
      path = path.replaceAll('replaceWithSpecialty', specialty.replaceAll(' ', '-').replaceAll('/', '-and-').replaceAll('&', 'and').replaceAll('--', '-').toLowerCase())
    }
  }

  return divisionBaseURL + path + '?iid=PDE-Open-Jobs&foxid=' + foxID
}
