
import PDETag from '@/components/pde/PDETag.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'Header',
  components: { PDETag, PDEHeading },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dateText: {
      type: String,
      default: '',
    },
  },

}
