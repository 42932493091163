import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-16",
  "data-test": "new-job-details-quickfacts"
}
const _hoisted_2 = { "data-test": "new-job-details-quickfacts-items" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.data.length>0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-12 font-600 text-black-800 tracking-widest" }, " QUICK FACTS ", -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (quickfact, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${quickfact}-${i}-${$props.id}`,
              class: "text-14 tx-font-400 text-black-700 mt-8"
            }, _toDisplayString(quickfact), 1))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}