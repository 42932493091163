
import PDEIcon from '@/components/pde/PDEIcon.vue'
export default {
  name: 'TravelCard',
  components: { PDEIcon },
  props: {
    travelData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['assignmentTravelClicked'],
  computed: {
    details() {
      return this.travelData?.details || []
    },
    iconTypes() {
      const types = new Set()
      let travelDetails
      for (let i = 0; i < this.details.length; i++) {
        travelDetails = this.details[i].travelDetails
        for (let j = 0; j < travelDetails.length; j++) {
          types.add(travelDetails[j].type)
        }
      }
      return types
    },
  },
  methods: {
    assignmentTravelClicked() {
      this.$emit('assignmentTravelClicked', this.travelData.title)

      const path = this.travelData.isPastTravel ? '/travel/completed' : '/travel'
      this.$router.push({ path, query: { pnr: this.travelData.pnr } })
    },
  },
}
