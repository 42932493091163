
import { mapActions, mapGetters } from 'vuex'
import { getAddressString } from '@/utils/assignments'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEMessageBox from '@/components/pde/PDEMessageBox.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import PDETag from '@/components/pde/PDETag.vue'

export default {
  name: 'Header',

  components: {
    PDEButton,
    PDEMessageBox,
    PDESkeleton,
    PDETag,
  },

  props: {
    // selected worksite
    details: {
      type: Object,
      default: () => {
        return {
          assignmentId: '',
          endDate: '',
          location: {
            city: '',
            state: '',
          },
          name: '',
          startDate: '',
          status: '',
          timeEntryEligible: false,
        }
      },
    },
  },

  computed: {
    ...mapGetters('account', ['division']),
    ...mapGetters('assignment', ['worksiteAddress', 'allAssignmentDetailsRequestsArePending']),
    ...mapGetters('identity', ['loggedInAsRep']),

    displayTimeEntryUnavailableMessage() {
      return !this.details.timeEntryEligible && this.details.status === 'confirmed'
    },

    displayTimeEntryExpiredMessage() {
      return !this.details.timeEntryEligible && this.details.status === 'completed'
    },

    getWorkSiteAddress() {
      const address = this.worksiteAddress(this.details.worksiteId)
      return address ? getAddressString(address) : ''
    },

    googleMapsUrl() {
      return `https://google.com/maps?q=${this.getWorkSiteAddress}`
    },

    payrollPhoneNumbers() {
      return this.$t(`myteams.jobnames.divisions.${this.division}.general`).find(contact => contact.name === 'Payroll')
    },
  },

  methods: {
    ...mapActions('navigation', ['openTimeEntryRedirectPage']),
    ...mapActions('analytics', ['sendClickEvent']),

    assignmentHeaderAnalytics(text) {
      this.sendClickEvent({
        area: 'action-pane/assignment',
        name: 'Assignment Header Click',
        text,
      })
    },

    handleEnterTimeClick() {
      this.assignmentHeaderAnalytics('enter-time')
      this.openTimeEntryRedirectPage({ assignmentId: this.details.assignmentId })
    },
  },
}
