
import ScheduleCard from '@/components/schedule/ScheduleCard.vue'

export default {
  name: 'ShiftDayList',
  components: {
    ScheduleCard,
  },
  props: {
    days: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['shiftSelected'],
  methods: {
    getDayOfTheWeek(date) {
      return this.$dayjs.tz(date).format('ddd')
    },
    getDayOfTheMonth(date) {
      return this.$dayjs.tz(date).format('D')
    },
  },
}

