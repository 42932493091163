
import { mapActions, mapGetters } from 'vuex'
import { formatDateToMMMDDYYYY } from '@/utils/assignments'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEAccordion from '@/components/pde/PDEAccordion.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'
import PDETag from '@/components/pde/PDETag.vue'
import PDEMessageBox from '@/components/pde/PDEMessageBox.vue'
import PDESvg from '@/components/pde/PDESvg.vue'

export default {
  name: 'MyJobs',

  components: {
    PDEAccordion,
    PDEButton,
    PDEHeading,
    PDEMessageBox,
    PDESkeleton,
    PDESvg,
    PDETag,
  },

  props: {
    confirmedJobs: {
      type: Array,
      default: () => [],
    },

    completedJobs: {
      type: Array,
      default: () => [],
    },

    selectedJob: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['jobSelected', 'viewCompleted'],
  data() {
    return {
      showedAll: false,
      isAccordionExpanded: true,
    }
  },

  computed: {
    ...mapGetters('assignment', ['assignmentWorksitesLoading']),

    showedJobs() {
      const displayCount = this.confirmedJobs.length >= 3 ? 3 : this.confirmedJobs.length
      return this.showedAll && this.confirmedJobs ? this.confirmedJobs : [...this.confirmedJobs].slice(0, displayCount)
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),

    getAwDateRange(aw) {
      const endDate = aw.endDate && aw.endDate !== aw.startDate ? ` - ${formatDateToMMMDDYYYY(aw.endDate)}` : ''
      return `${formatDateToMMMDDYYYY(aw.startDate)}${endDate}`
    },

    toggleShowAll() {
      this.showedAll = !this.showedAll
      if (this.showedAll) {
        this.sendClickEvent({
          name: 'My Jobs Load More',
          text: 'Load More',
          area: 'action-pane/my-jobs',
        })
      }
    },

    handleMyJobsAccordionClick({ isOpen }) {
      this.isAccordionExpanded = isOpen
      isOpen
        ? this.sendClickEvent({
          name: 'My Jobs Expand Section',
          text: 'Expand My Jobs',
          area: 'action-pane/my-jobs',
        })
        : this.sendClickEvent({
          name: 'My Jobs Collapse Section',
          text: 'Collapse My Jobs',
          area: 'action-pane/my-jobs',
        })
    },

    isSelected(aw) {
      return this.selectedJob.assignmentId === aw.assignmentId && this.selectedJob.worksiteId === aw.worksiteId ? 'bg-highlight-50 cursor-default' : 'cursor-pointer'
    },
  },
}

