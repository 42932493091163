
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'PDECheckbox',

  components: {
    PDEIcon,
  },

  props: {
    name: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    labelClasses: {
      type: String,
      default: 'text-14',
    },

    modelValue: {
      type: Boolean,
      required: true,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },

    checkboxClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validate needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      handleChange,
    } = useField(name, undefined, {
      type: 'checkbox',
      checkedValue: true,
      uncheckedValue: false,
      syncVModel: true,
    })

    return {
      handleChange,
    }
  },
  computed: {
    checkmarkClasses() {
      if (this.modelValue || this.indeterminate) return 'bg-primary-500'

      return 'border-solid border-2 border-pureblack-40'
    },
  },
}
