import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-16",
  "data-test": "new-job-details-description"
}
const _hoisted_2 = {
  class: "text-14 tx-font-400 text-black-700 mt-8 whitespace-pre-line",
  "data-test": "new-job-details-description-text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($props.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-12 font-600 text-black-800 tracking-widest" }, " DESCRIPTION ", -1)),
        _createElementVNode("p", _hoisted_2, _toDisplayString($props.data), 1)
      ]))
    : _createCommentVNode("", true)
}