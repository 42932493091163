import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex p-0 h-full w-full cancel-padding cancel-margin m-0 overflow-hidden" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ActionPane = _resolveComponent("ActionPane")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_CVUploadPrompt = _resolveComponent("CVUploadPrompt")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionPane, {
      class: "w-full sm:w-376 bg-pureblack-0",
      "calendar-items": $options.getCalendarItems,
      "is-loading": $data.actionPaneLoading,
      "is-mobile": $setup.isMobile,
      "confirmed-jobs": _ctx.confirmed,
      "completed-jobs": $options.getAllCompletedJobs,
      "shift-data": _ctx.shiftData,
      "selected-job": _ctx.selectedAssignmentWorksite,
      onJobSelected: $options.jobSelected,
      onShiftSelected: $options.shiftSelected
    }, null, 8, ["calendar-items", "is-loading", "is-mobile", "confirmed-jobs", "completed-jobs", "shift-data", "selected-job", "onJobSelected", "onShiftSelected"]),
    (!$setup.isMobile)
      ? (_openBlock(), _createBlock(_component_Calendar, {
          key: 0,
          class: "border-solid border-l-1 border-pureblack-20 flex-1",
          "data-test": "calendar-desktop-container",
          items: $options.getCalendarItems,
          "selected-assignment-worksite": _ctx.selectedAssignmentWorksite,
          "selected-itinerary": $options.selectedItinerary,
          loading: $data.calendarLoading,
          onCalendarJobSelected: $options.jobSelected
        }, null, 8, ["items", "selected-assignment-worksite", "selected-itinerary", "loading", "onCalendarJobSelected"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      "enter-from-class": "opacity-0 translate-x-1/4",
      "enter-active-class": "transition-all duration-1000 delay-[3500ms] pointer-events-none",
      "enter-to-class": "opacity-100 translate-x-0",
      "leave-from-class": "opacity-100",
      "leave-active-class": "transition-all duration-700 pointer-events-none",
      "leave-to-class": "opacity-0",
      onAfterLeave: _ctx.openNextToast
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CVUploadPrompt, {
          show: _ctx.$featureFlags.cvUploadPrompt13387 && _ctx.hasCVRequest === false && !_ctx.cvPromptDismissed,
          "data-test": "cv-upload-prompt",
          class: "xs:w-304 fixed left-16 xs:left-auto bottom-16 xs:bottom-24 right-16 xs:right-24",
          onPromptClosed: $options.disposePrompt
        }, null, 8, ["show", "onPromptClosed"])
      ]),
      _: 1
    }, 8, ["onAfterLeave"])
  ]))
}