import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overflow-y-auto h-full z-1 *:pt-48 *:px-24 *:pb-32 *:border-b-1 *:border-pureblack-20 *:border-solid",
  "data-test": "job-details"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header")
  const _component_Schedule = _resolveComponent("Schedule")
  const _component_Worksite = _resolveComponent("Worksite")
  const _component_Travel = _resolveComponent("Travel")
  const _component_MyTeam = _resolveComponent("MyTeam")

  return ($props.details)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header, {
          details: $props.details,
          class: "first:pt-24"
        }, null, 8, ["details"]),
        _createVNode(_component_Schedule, {
          "assignment-id": $props.details.assignmentId,
          "worksite-id": $props.details.worksiteId,
          "schedule-description": _ctx.assignmentDetails.scheduleDescription,
          "assignment-status": $props.details.status
        }, null, 8, ["assignment-id", "worksite-id", "schedule-description", "assignment-status"]),
        _createVNode(_component_Worksite, { "assignment-details": _ctx.assignmentDetails }, null, 8, ["assignment-details"]),
        _createVNode(_component_Travel),
        _createVNode(_component_MyTeam, {
          "assignment-id": $props.details.assignmentId,
          class: "last:border-0"
        }, null, 8, ["assignment-id"])
      ]))
    : _createCommentVNode("", true)
}