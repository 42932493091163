import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-pureblack-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MyJobs = _resolveComponent("MyJobs")
  const _component_NewJobs = _resolveComponent("NewJobs")

  return (_openBlock(), _createElementBlock("div", {
    id: "jobs-list",
    "data-test": "jobs-list",
    class: "overflow-y-auto h-full scroll-smooth bg-pureblack-10 [-webkit-transform:translateZ(0)]",
    onScroll: _cache[2] || (_cache[2] = (...args) => ($options.scrollHandler && $options.scrollHandler(...args)))
  }, [
    _createVNode(_component_MyJobs, {
      "confirmed-jobs": $props.confirmedJobs,
      "completed-jobs": $props.completedJobs,
      "selected-job": $props.selectedJob,
      onJobSelected: _cache[0] || (_cache[0] = (aw) => _ctx.$emit('jobSelected', aw)),
      onViewCompleted: _cache[1] || (_cache[1] = (aw) => _ctx.$emit('viewCompleted', aw))
    }, null, 8, ["confirmed-jobs", "completed-jobs", "selected-job"]),
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        class: "h-8 bg-pureblack-10 border-solid border-t-1 border-b-1 border-pureblack-20",
        "data-test": "new-jobs-component"
      }, null, -1)),
      (_ctx.userDetailsLoaded)
        ? (_openBlock(), _createBlock(_component_NewJobs, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 32))
}