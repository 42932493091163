
import { mapActions, mapGetters, mapState } from 'vuex'
import PDETabs from '@/components/pde/PDETabs.vue'
import JobsList from '@/components/jobs/JobsList/JobsList.vue'
import CompletedList from '@/components/jobs/JobsList/CompletedList.vue'
import Calendar from '@/components/jobs/Calendar/Calendar.vue'
import ShiftMonthList from '@/components/jobs/ShiftsList/ShiftMonthList.vue'
import PDEIconButton from '@/components/pde/PDEIconButton.vue'
import PDEDrawer from '@/components/pde/PDEDrawer.vue'
import JobDetails from '@/components/jobs/JobDetails'
import { jobsGenerateDynamicURL } from '@/utils/jobsGenerateDynamicURL'
import NewJobDetails from '@/components/jobs/NewJobDetails'
import TravelItineraryDetail from '@/components/account/Travel/TravelItineraryDetail.vue'

export default {
  name: 'ActionPane',

  components: {
    PDETabs,
    Calendar,
    JobsList,
    CompletedList,
    ShiftMonthList,
    PDEIconButton,
    PDEDrawer,
    JobDetails,
    NewJobDetails,
    TravelItineraryDetail,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },

    calendarItems: {
      type: Array,
      default: () => [],
    },

    confirmedJobs: {
      type: Array,
      default: () => [],
    },

    completedJobs: {
      type: Array,
      default: () => [],
    },

    selectedJob: {
      type: Object,
      default: () => ({}),
    },

    shiftData: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['jobSelected', 'shiftSelected'],

  data: () => ({
    showCompleted: false,
    showAsgDetailsDrawer: false,
    goToScheduleDay: null,
  }),

  computed: {
    ...mapGetters('assignment', ['assignmentWorksiteIsSelected']),
    ...mapGetters('account', ['primarySpecialty', 'division', 'getContactId']),
    ...mapGetters('navigation', ['newJobDetailsOpen']),
    ...mapGetters('travel', ['showTravelDetail']),
    ...mapState('navigation', ['activeActionPaneTab']),

    tabs() {
      const tabs = [
        {
          name: 'Jobs',
          key: 'assignments',
        },
        {
          name: 'Schedule',
          key: 'shifts',
        },
      ]

      if (this.isMobile) tabs.push({ name: 'Calendar', key: 'calendar' })

      return tabs
    },

    getSelectedWorksite() {
      const jobs = this.confirmedJobs.concat(this.completedJobs)
      return jobs.find(cj => cj.worksiteId === this.selectedJob.worksiteId && cj.assignmentId === this.selectedJob.assignmentId)
    },
  },

  watch: {
    assignmentWorksiteIsSelected(newVal) {
      if (newVal) {
        this.closeNewJobDrawer()
        this.resetTravelItineraryDetail()
      }
    },

    isMobile(newVal) {
      if (newVal === false && this.activeActionPaneTab === 'calendar') {
        this.setActiveActionPaneTab(this.tabs[0].key)
      }
    },

    newJobDetailsOpen(newVal) {
      if (newVal) {
        this.closeAssignmentWorksite()
        this.closeCompleted()
        this.resetTravelItineraryDetail()
      }
    },

    showCompleted(newVal) {
      if (newVal) {
        this.closeNewJobDrawer()
        this.resetTravelItineraryDetail()
      }
    },

    showTravelDetail(newVal) {
      if (newVal) {
        this.closeAssignmentWorksite()
        this.closeCompleted()
        this.closeNewJobDrawer()
      }
    },
  },

  beforeUnmount() {
    this.closeAssignmentWorksite()
    this.resetTravelItineraryDetail()
    this.closeNewJobDrawer()
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('assignment', ['closeAssignmentWorksite']),
    ...mapActions('navigation', ['setActiveActionPaneTab', 'toggleNewJobDetails']),
    ...mapActions('jobs', ['resetNewJobDetailsData']),
    ...mapActions('travel', ['resetTravelBookings', 'resetTravelItineraryDetail']),

    goToSchedule(day) {
      this.goToScheduleDay = day
      this.setActiveActionPaneTab('shifts')
    },

    tabClicked(tabName) {
      this.sendClickEvent({
        name: 'Action Pane Tab Click',
        text: tabName,
        area: 'action-pane/assignment',
      })
      this.setActiveActionPaneTab(tabName)
    },

    assignmentDetailsBackClick() {
      this.sendClickEvent({
        name: 'Assignment Detail Back Button Click',
        text: 'back',
        area: 'action-pane/assignment',
      })
      this.closeAssignmentWorksite()
    },

    openCompleted() {
      this.showCompleted = true
      this.sendClickEvent({
        name: 'Action Pane Click',
        text: 'View Completed Assignments',
        area: 'action-pane/assignment',
      })
    },

    openBrowseJobsTab() {
      this.sendClickEvent({
        name: 'Jobs Button Click',
        text: 'Browse Jobs',
        area: 'action-pane/jobs',
      })
      const divisionJobsURL = jobsGenerateDynamicURL(this.primarySpecialty, this.getContactId, this.division)
      window.open(divisionJobsURL, '_blank')
    },

    closeCompleted() {
      this.showCompleted = false
      this.sendClickEvent({
        name: 'Action Pane Click',
        text: 'Back to Assignments',
        area: 'action-pane/assignment-completed',
      })
    },

    handleTermsAndConditionsClick(event) {
      this.sendClickEvent({
        name: 'Shift Tab Terms and Conditions Click',
        event,
        area: 'action-pane/schedule',
      })
    },
    closeNewJobDrawer() {
      if (this.newJobDetailsOpen) {
        this.resetNewJobDetailsData()
        this.toggleNewJobDetails()
      }
    },
  },
}
