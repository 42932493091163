import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "overflow-y-auto new-job-details-wrapper" }
const _hoisted_2 = {
  key: 0,
  "data-test": "new-job-details-schedule",
  class: "py-8 mx-16 flex leading-6 items-center"
}
const _hoisted_3 = {
  key: 1,
  class: "px-16"
}
const _hoisted_4 = { class: "text-14 tx-font-400 text-black-700" }
const _hoisted_5 = {
  key: 2,
  class: "text-12 p-16 border-t-1 border-solid border-pureblack-10",
  "data-test": "new-job-details-disclaimer"
}
const _hoisted_6 = {
  class: "text-14 font-400 text-black-700 mt-8",
  "data-test": "new-job-details-disclaimer-text"
}
const _hoisted_7 = { class: "send-intereset-row pde-sticky bottom-0 w-full mt-8 bg-pureblack-0 border-0 border-solid border-t-1 border-pureblack-20 px-16" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header")
  const _component_Labels = _resolveComponent("Labels")
  const _component_QuickFacts = _resolveComponent("QuickFacts")
  const _component_app_link = _resolveComponent("app-link")
  const _component_Description = _resolveComponent("Description")
  const _component_SendInterest = _resolveComponent("SendInterest")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Header, {
        id: _ctx.newJobDetailsData.id ,
        title: _ctx.newJobDetailsData.title,
        "date-text": _ctx.newJobDetailsData.dateText,
        "data-test": "new-job-details-header"
      }, null, 8, ["id", "title", "date-text"]),
      (!$setup.isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("div", null, [
              _createElementVNode("div", { class: "bg-selected-job h-24 w-24 bg-success-100 rounded-3" })
            ], -1),
            _createElementVNode("div", { class: "text-12 font-600 text-black-800 ml-8" }, " The schedule and dates for this job are flexible. ", -1)
          ])))
        : _createCommentVNode("", true),
      _createVNode(_component_Labels, {
        location: _ctx.newJobDetailsData.locationLabel,
        "salary-text": _ctx.newJobDetailsData.salaryText,
        "is-state-match": _ctx.newJobDetailsData.stateMatch,
        "is-imlc": _ctx.newJobDetailsData.isIMLC,
        "data-test": "new-job-details-labels"
      }, null, 8, ["location", "salary-text", "is-state-match", "is-imlc"]),
      _createVNode(_component_QuickFacts, {
        id: _ctx.newJobDetailsData.id,
        data: _ctx.newJobDetailsData.quickFacts,
        "data-test": "new-job-details-quick-facts"
      }, null, 8, ["id", "data"]),
      ($options.isImlcViewable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createTextVNode(" IMLC: The Interstate Medical Licensure Compact simplifies the process of obtaining a medical license in 38 U.S. states and one territory if you already have a primary license in one participating state. ")),
              _createVNode(_component_app_link, {
                id: "imlc-details-link",
                title: "Read more about IMLC",
                to: _ctx.division==='CHS' ? 'https://comphealth.com/resources/interstate-medical-licensure-compact' : 'https://weatherbyhealthcare.com/blog/interstate-medical-licensure-compact',
                target: "_blank",
                class: "no-underline text-highlight-800 hover:text-highlight-600",
                onClick: $options.handleIMLCDetailsClick
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Read more about IMLC ")
                ])),
                _: 1
              }, 8, ["to", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Description, {
        data: _ctx.newJobDetailsData.description,
        "data-test": "new-job-details-description"
      }, null, 8, ["data"]),
      (_ctx.newJobDetailsData.salaryText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-600 text-black-800" }, " PAY RANGE DISCLAIMER ", -1)),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.newJobDetailsData.salaryDisclaimer && _ctx.$featureFlags.conditionalPayRangeDisclaimer10965)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.newJobDetailsData.salaryDisclaimer), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('help.jobs.tags.payDisclaimer')), 1)
                  ], 64))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_SendInterest)
    ])
  ]))
}