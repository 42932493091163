
import PDEIcon from '@/components/pde/PDEIcon.vue'
import { mapActions } from 'vuex'
import { getTimelinePosition } from '@/utils/getTimelinePosition'

export default {
  name: 'ScheduleCard',
  components: {
    PDEIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['shiftSelected'],
  computed: {
    /**
     * Content
     */
    cardStyles() {
      const styles = {
        past: 'bg-pureblack-5 border-pureblack-35 text-black-600 group-[.focused-day]:animate-schedule-card-focus-past',
        assignment: 'bg-highlight-50 border-highlight-800 text-black-800 group-[.focused-day]:animate-schedule-card-focus-assignment',
        travel: 'bg-primary-50 border-primary-500 text-black-800 group-[.focused-day]:animate-schedule-card-focus-travel',
      }
      return styles[this.isInPast ? 'past' : this.cardType]
    },

    icon() {
      if (this.cardType === 'assignment' && this.item.type === 'On Call') return 'phone-fill'

      if (this.cardType === 'travel') {
        if (this.item.type === 'flight') return 'plane-fill'
        if (this.item.type === 'carRental') return 'car-fill'
        return 'house-fill'
      }

      return ''
    },

    iconColor() {
      return this.cardType === 'assignment' ? 'text-highlight-800' : 'text-primary-500'
    },

    location() {
      const a = null
      const b = null
      if (this.cardType === 'assignment') return { a: this.item.worksite.name, b }

      if (this.cardType === 'travel') {
        if (this.item.type === 'flight') return { a: `${this.item.departureCity}, ${this.item.departureState}`, b: `${this.item.arrivalCity}, ${this.item.arrivalState}` }
        else if (this.item.type === 'carRental') {
          const location = this.item.isPickup ? 'pickup' : 'dropoff'
          return { a: `${this.item[location].address} ${this.item[location].city}, ${this.item[location].province}`, b }
        }
        return { a: this.item.address, b } // hotel is address whether checking in or not
      }

      return { a, b }
    },

    time() {
      if (this.cardType === 'assignment') return this.assignmentTimes

      if (this.item.type === 'flight') return `Departure: ${this.$dayjs(this.item.departureDateAndTime).format('h:mma')}`
      if (this.item.type === 'carRental') return this.item.isPickup ? `Pick up: ${this.$dayjs(this.item.pickup.time).format('h:mma')}` : `Drop off: ${this.$dayjs(this.item.dropoff.time).format('h:mma')}`
      return this.item.isCheckin ? `Check in: ${this.$dayjs(this.item.checkinDateTime).format('h:mma')}` : `Check out: ${this.$dayjs(this.item.checkoutDateTime).format('h:mma')}`
    },

    title() {
      if (this.cardType === 'assignment') return `${this.item.worksite.location.city}, ${this.item.worksite.location.state}`

      if (this.item.type === 'flight') return this.item.airlineName
      return this.item.name // applies to carRental and hotel travel objects
    },

    type() {
      const types = {
        flight: 'Flight',
        carRental: 'Car',
        hotel: 'Hotel',
      }

      return types[this.item.type] || this.item.type
    },

    /**
     * Helpers
     */
    cardType() {
      return this.item.worksite ? 'assignment' : 'travel'
    },

    displayDate() {
      const start = this.$dayjs.tz(this.item.startDate)
      const end = this.$dayjs.tz(this.item.endDate)

      if (end > start) {
        const endDateFormat = end.isAfter(start, 'month') ? 'MMM D' : 'D'
        return `(${start.format('MMM D')} - ${end.format(endDateFormat)})`
      }

      return ''
    },

    // Assignment only
    assignmentTimes() {
      if (this.item.startTime && this.item.endTime) {
        return `${this.startTime} - ${this.endTime}`
      } else if (this.item.startTime) {
        return `${this.startTime}`
      }
      return 'Hours not available'
    },

    // assignment only
    startTime() {
      return this.$dayjs(this.item.startTime, 'HH:mm:ss').format('h:mma').replace('m', '')
    },

    // assignment only
    endTime() {
      return this.$dayjs(this.item.endTime, 'HH:mm:ss').format('h:mma').replace('m', '')
    },

    isInPast() {
      if (this.cardType === 'assignment') return this.$dayjs.tz(this.item.endDate).isBefore(this.$dayjs().endOf('day'), 'day')
      return this.item.dayJsEnd.isBefore(this.$dayjs().endOf('day'), 'day')
    },
  },
  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    ...mapActions('travel', ['setTravelItineraryDetail']),

    cardClickHandler() {
      if (this.cardType === 'assignment') {
        const assignmentWorksite = {
          ...this.item,
          ...this.item.worksite,
        }
        this.$emit('shiftSelected', assignmentWorksite)
        this.sendClickEvent({
          name: 'Action Pane Assignment Click',
          text: 'Assignment Card',
          area: 'action-pane/schedule',
          position: `${getTimelinePosition(this.item.startDate, this.item.endDate)} Assignment`,
        })
      } else if (this.cardType === 'travel') {
        this.sendClickEvent({
          name: 'Action Pane Travel Click',
          text: 'Travel Card',
          area: 'action-pane/schedule',
          position: `${this.item.timelinePosition} Travel`,
        })
        this.setTravelItineraryDetail({ pnr: this.item.pnr, date: this.item.sortDate })
      }
    },
  },
}

