import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-4"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex relative text-12 font-600 font-default text-primary-500", [$props.backgroundColor, $options.containerStyleClasses]])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle([{ width: $options.getBarPercentage + '%' }]),
      class: _normalizeClass([[$options.barStyleClasses], "absolute bg-success-600 bottom-0 left-0 transition-all duration-500 ease-in-out transform"])
    }, null, 6),
    _createElementVNode("div", {
      class: _normalizeClass([[$options.getContentZindex], "w-full"])
    }, [
      ($props.barStyle === 'bar')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 2)
  ], 2))
}