
import { getAddressString } from '@/utils/assignments'
import { mapGetters, mapActions } from 'vuex'
import Formatter from '@/utils/Formatter'
import DeFormatter from '@/utils/DeFormatter'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'

export default {
  name: 'Worksite',

  components: {
    PDEHeading,
    PDESkeleton,
  },

  props: {
    assignmentDetails: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters('assignment', ['areAnyAssignmentDetailsRequestsPending']),
    getWorkSiteAddress() {
      return this.assignmentDetails ? getAddressString(this.assignmentDetails.address) : ''
    },
    getWhenToArrive() {
      const dateTime = `${this.assignmentDetails.whenToArrive.date} ${this.assignmentDetails.whenToArrive.time}`

      return this.$dayjs
        .utc(dateTime, ['YYYY-MM-DD HH:mm:ss.SSSZ'])
        .format('dddd, MMMM Do h:mm a')
    },
    getFormattedPhone() {
      return Formatter.phone(this.assignmentDetails.phone)
    },
    googleMapsUrl() {
      return `https://google.com/maps?q=${this.getWorkSiteAddress}`
    },
  },

  methods: {
    ...mapActions('analytics', ['sendClickEvent']),
    assignmentDetailsWorksiteAnalytics(text) {
      this.sendClickEvent({
        area: 'action-pane/assignment',
        name: 'Assignment Worksite Click',
        text: `First day ${text} click`,
      })
    },
    phoneLink: phone => `tel:+${DeFormatter.phone(phone)}`,
  },
}
