
import { mapActions, mapGetters } from 'vuex'
import LogRocket from 'logrocket'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEIcon from '@/components/pde/PDEIcon.vue'

export default {
  name: 'SendInterest',

  components: {
    PDEButton,
    PDEIcon,
  },

  data: () => {
    return {
      leadComment: '',
      isLoading: false,
      jobHasInterest: false,
    }
  },

  computed: {
    ...mapGetters('jobs', ['sendJobToRepModalDetails', 'newJobDetailsData']),
    ...mapGetters('identity', ['loggedInAsRep']),
    ...mapGetters('account', ['providerRep', 'isPartialUser', 'primarySpecialty']),
    buttonLabel() {
      return this.isLoading ? 'Sending...' : 'Send interest'
    },
    repMessage() {
      if (!this.providerRep?.name) return 'Your Provider Rep will reach out soon'

      return this.providerRep?.name?.toLowerCase().includes('unassigned')
        ? 'Thank you for your interest, a representative will reach out to you soon!'
        : `${this.providerRep?.name}, your rep, will reach out soon.`
    },
  },

  watch: {
    // need this watcher to get the new current job in detail view when changed
    newJobDetailsData(newValue) {
      this.setJobInterestStatus(newValue)

      if (newValue?.id === null) return

      this.leadComment = ''
      document.querySelector('#rep-comment').style.height = '35px'
    },
  },

  methods: {
    ...mapActions('jobs', [
      'resetSendJobToRepModalDetails',
      'sendLeadRequest',
      'setJobAsSentToRep',
    ]),
    ...mapActions('analytics', ['sendClickEvent']),

    setJobInterestStatus(job) {
      this.jobHasInterest = job?.Interests ? job.Interests.length > 0 : false
    },

    autoResize(e) {
      this.leadComment = e.target.value
      e.target.style.height = '35px'
      e.target.style.height = `${e.target.scrollHeight > 69 ? '69' : e.target.scrollHeight}px`
    },

    handleSendInterestAnalytics() {
      this.sendClickEvent({
        name: 'Detail View Send Interest',
        text: 'Send interest (detail view)',
        area: 'action-pane/job-details',
      })
    },

    async sendToRepAndTrackAnalytics() {
      if (this.isLoading) return

      this.isLoading = true
      if (!this.leadComment) this.leadComment = "I'm interested in this job"
      try {
        await this.sendLeadRequest({ job: this.sendJobToRepModalDetails, leadComment: this.leadComment })
        this.setJobAsSentToRep(this.sendJobToRepModalDetails?.id)
      } catch (err) {
        LogRocket.captureException(err)
      } finally {
        this.jobHasInterest = true
        this.isLoading = false
        this.handleSendInterestAnalytics()
      }
      setTimeout(() => {
        this.resetSendJobToRepModalDetails()
        this.leadComment = ''
      }, 500)
    },
  },

}
