import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { "data-test": "completed-list" }
const _hoisted_2 = ["data-test"]
const _hoisted_3 = { class: "pde-sticky bg-pureblack-0 h-32 flex items-center text-12 font-600 text-black-800 pl-16" }
const _hoisted_4 = ["data-test", "onClick"]
const _hoisted_5 = { class: "flex border-0 border-solid border-b-1 border-pureblack-20 justify-between items-start py-16 pr-16 pl-8" }
const _hoisted_6 = { class: "flex flex-col space-y-2" }
const _hoisted_7 = { class: "text-12 text-pureblack-50 uppercase" }
const _hoisted_8 = { class: "text-16 text-pureblack-60 font-700" }
const _hoisted_9 = { class: "text-14 text-pureblack-60" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getCompletedByYear, ({ year, aws }) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${year}`,
        "data-test": `${year}`
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(year), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(aws, (aw) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${aw.assignmentId}-${aw.worksiteId}`,
            "data-test": `${aw.assignmentId}-${aw.worksiteId}`,
            class: _normalizeClass(["hover:bg-highlight-50 pl-24", [$options.isSelected(aw)]]),
            onClick: () => _ctx.$emit('jobSelected', aw)
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString($options.getAwDateRange(aw)), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(aw.location.city) + ", " + _toDisplayString(aw.location.state), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(aw.name), 1)
              ])
            ])
          ], 10, _hoisted_4))
        }), 128))
      ], 8, _hoisted_2))
    }), 128))
  ]))
}