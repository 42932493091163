
import PDETag from '@/components/pde/PDETag.vue'
export default {
  name: 'Labels',
  components: { PDETag },
  props: {
    salaryText: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: '',
    },
    isStateMatch: {
      type: Boolean,
      default: false,
    },
    isImlc: {
      type: Boolean,
      default: false,
    },
  },

}
