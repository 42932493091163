import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-16 p-16 box-border bg-pureblack-5" }
const _hoisted_2 = {
  class: "font-700 text-14 text-black-800",
  "data-test": "no-new-jobs-title"
}
const _hoisted_3 = {
  class: "font-400 text-14 italic mt-8 text-black-600",
  "data-test": "no-new-jobs-content"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString($props.message), 1),
    _renderSlot(_ctx.$slots, "default")
  ]))
}