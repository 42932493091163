
import { mapActions, mapGetters } from 'vuex'
import ScheduleType from '@/components/schedule/ScheduleType.vue'
import PDEButton from '@/components/pde/PDEButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'
import PDESkeleton from '@/components/pde/PDESkeleton.vue'

export default {
  name: 'Schedule',

  components: {
    PDEButton,
    PDEHeading,
    PDESkeleton,
    ScheduleType,
  },

  props: {
    assignmentId: {
      type: String,
      default: '',
    },
    worksiteId: {
      type: String,
      default: '',
    },
    scheduleDescription: {
      type: String,
      default: '',
    },
    assignmentStatus: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showAllShifts: false,
      showMore: true,
    }
  },

  computed: {
    ...mapGetters('shifts', ['scheduleData', 'scheduleDataLoading']),

    ...mapGetters('assignment', ['allAssignmentDetailsRequestsArePending', 'worksiteLocationMap']),

    generateAllShifts() {
      return Object.entries(this.scheduleData).reduce((acc, [shiftType]) => {
        // Loop through each month
        const months = Object.entries(this.scheduleData[shiftType]).reduce((monthAcc, [month, days]) => {
          // Loop through each day
          const allDays = Object.entries(days).reduce((daysAcc, [day, shifts]) => (
            shifts.length > 0
              ? daysAcc.concat([[day, shifts]])
              : daysAcc
          ), [])

          // we only want to add the month if there are days
          return allDays.length > 0
            ? monthAcc.concat([[month, shiftType === 'completed' ? allDays.reverse() : allDays]])
            : monthAcc
        }, [])

        if (shiftType === 'completed') months.reverse()
        acc[shiftType] = months

        return acc
      }, { upcoming: [], completed: [] })
    },

    getScheduleData() {
      // return all shifts if we need to display them all
      if (this.showAllShifts) { return this.generateAllShifts }

      // else get the first 5 shifts
      const maxShifts = 5
      const schedule = Object.entries(this.generateAllShifts).reduce((acc, [shiftType]) => {
        const months = this.generateAllShifts[shiftType].reduce((dataAcc, [month, days]) => {
          const newDays = days.reduce((daysAcc, [day, shifts]) => {
            if (acc.total >= maxShifts) { return daysAcc }

            const newShifts = shifts.slice(0, maxShifts - acc.total)
            acc.total += newShifts.length

            return daysAcc.concat([[day, newShifts]])
          }, [])

          return dataAcc.concat([[month, newDays]])
        }, [])

        acc.data[shiftType] = months

        return acc
      }, { data: { upcoming: [], completed: [] }, total: 0 })

      return schedule.data
    },

    upcomingShifts() {
      return this.getScheduleData?.upcoming ?? []
    },

    completedShifts() {
      return this.getScheduleData?.completed ?? []
    },

    getTotalShifts() {
      // count all the total shifts in the shifts array
      const count = Object.entries(this.generateAllShifts).reduce((acc, [shiftType]) => {
        acc += this.generateAllShifts[shiftType].reduce((monthsAcc, [, days]) => {
          const totalShifts = days.reduce((total, [, shifts]) => total + shifts.length, 0)

          return monthsAcc + totalShifts
        }, 0)

        return acc
      }, 0)

      return count
    },
  },

  watch: {
    worksiteId: function (newValue) {
      this.getShiftsByAssignmentsWithWorksite({
        worksiteId: newValue,
        assignmentId: this.assignmentId,
        locationMap: this.worksiteLocationMap,
      })
    },

    assignmentId: function (newValue) {
      this.getShiftsByAssignmentsWithWorksite({
        worksiteId: this.worksiteId,
        assignmentId: newValue,
        locationMap: this.worksiteLocationMap,
      })
    },
  },

  mounted() {
    if (this.worksiteId && this.assignmentId) {
      this.getShiftsByAssignmentsWithWorksite({
        worksiteId: this.worksiteId,
        assignmentId: this.assignmentId,
        locationMap: this.worksiteLocationMap,
      })
    }
  },

  methods: {
    ...mapActions('shifts', ['getShiftsByAssignmentsWithWorksite']),

    formatDescriptionText(string, limit) {
      if (string.length > limit && this.showMore) {
        string = string.substring(0, limit)
      }
      return string
    },
  },
}
